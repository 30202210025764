// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #85ad3a;
  --ion-color-success-rgb: 133, 173, 58;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #759833;
  --ion-color-success-tint: #91b54e;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #de5645;
  --ion-color-danger-rgb: 222, 86, 69;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #c34c3d;
  --ion-color-danger-tint: #e16758;

  /** dark **/
  --ion-color-dark: #1d1e1b;
  --ion-color-dark-rgb: 29, 30, 27;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1a1a18;
  --ion-color-dark-tint: #343532;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  //Colors
  --color-whitesmoke: #f4f5f6;
  --color-lighter-blue: #ebf4f9;
  --color-light-blue: #c8e1ef;
  --color-blue: #3b9dd0;
  --color-dark-blue: #1f8ec6;

  --color-light-green: #e7efd7;
  --color-dark-green: #5a830e;
  --color-text: #565c65;
  //Borders
  --border-color: #d6dadc;
  --border-color-2: #e0e2e4;
  --default-border: 1px solid var(--border-color);
  --default-border-2: 1px solid var(--border-color-2);
  --text-color: #2c333e;

  --ion-color-orange: #f19520;
  --ion-color-orange-rgb: 241, 149, 32;
  --ion-color-orange-contrast: #ffffff;
  --ion-color-orange-contrast-rgb: 255, 255, 255;
  --ion-color-orange-shade: #d4831c;
  --ion-color-orange-tint: #f2a036;
}

.ion-color-orange {
  --ion-color-base: var(--ion-color-orange);
  --ion-color-base-rgb: var(--ion-color-orange-rgb);
  --ion-color-contrast: var(--ion-color-orange-contrast);
  --ion-color-contrast-rgb: var(--ion-color-orange-contrast-rgb);
  --ion-color-shade: var(--ion-color-orange-shade);
  --ion-color-tint: var(--ion-color-orange-tint);
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/Montserrat/Montserrat-Regular.ttf');
}

@font-face {
  font-family: 'Montserrat';
  font-style: semi-bold;
  font-weight: 500 600;
  src: url('../assets/fonts/Montserrat/Montserrat-SemiBold.ttf');
}

@font-face {
  font-family: 'Montserrat';
  font-style: bold;
  font-weight: 700 800;
  src: url('../assets/fonts/Montserrat/Montserrat-Bold.ttf');
}

@font-face {
  font-family: 'Montserrat';
  font-style: extra-bold;
  font-weight: 900;
  src: url('../assets/fonts/Montserrat/Montserrat-ExtraBold.ttf');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../assets/fonts/opensans/OpenSans-Regular.ttf');
}

@font-face {
  font-family: 'Open Sans';
  font-style: semi-bold;
  font-weight: 500 600;
  src: url('../assets/fonts/opensans/OpenSans-SemiBold.ttf');
}

@font-face {
  font-family: 'Open Sans';
  font-style: bold;
  font-weight: 700 800;
  src: url('../assets/fonts/opensans/OpenSans-Bold.ttf');
}

@font-face {
  font-family: 'Open Sans';
  font-style: extra-bold;
  font-weight: 900;
  src: url('../assets/fonts/opensans/OpenSans-ExtraBold.ttf');
}
